import React from "react";
import { ModuleBanner } from "../../../contentful/PageModularModule";
import { Banner } from "@carfax/web-ui/components/Banner";

interface Props {
  module?: ModuleBanner;
}

const PromotionBanner: React.FC<Props> = ({
  module,
  module: {
    title,
    description,
    dateToEndCampaign,
    optionalImage,
    themePattern,
    daysLabel,
    hoursLabel,
    minutesLabel,
    secondsLabel,
  },
}) => {
  return (
    <>
      {module && (
        <Banner
          title={title}
          description={description}
          dateToEndCampaign={dateToEndCampaign}
          optionalImage={optionalImage}
          themePattern={themePattern}
          daysLabel={daysLabel}
          hoursLabel={hoursLabel}
          minutesLabel={minutesLabel}
          secondsLabel={secondsLabel}
        />
      )}
    </>
  );
};

export default PromotionBanner;
