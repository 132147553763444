import { colors, spacing } from "../../../core";

enum ThemeName {
  HALLOWEEN = "halloween",
  BLACK_FRIDAY = "black friday",
}

export interface ThemeStyles {
  background?: string;
  titleColor?: string;
  descriptionColor?: string;
  counterColor?: string;
  padding?: string;
  paddingOnMobile?: string;
}

export const useThemePattern = (theme): ThemeStyles => {
  switch (theme) {
    case ThemeName.HALLOWEEN:
      return {
        background: colors.halloweenPurple.hex,
        titleColor: colors.white.hex,
        descriptionColor: colors.white.hex,
        counterColor: colors.strongOrange.hex,
        padding: spacing.baseUnitMobile2,
        paddingOnMobile: "20px",
      };
    case ThemeName.BLACK_FRIDAY:
      return {
        background: colors.black.hex,
        titleColor: colors.white.hex,
        descriptionColor: colors.white.hex,
        counterColor: colors.red.hex,
        padding: spacing.baseUnitMobile2,
        paddingOnMobile: "20px",
      };
    default:
      return {
        background: colors.green.hex,
        titleColor: colors.black.hex,
        padding: `6px 0`,
        paddingOnMobile: "0",
      };
  }
};
