import { css } from "@emotion/react";
import { colors, mediaQuery, fontSizes, spacing, letterSpacings } from "../../core";
import { ThemeStyles } from "./utils/useThemePattern";

export const styles = {
  wrapper: (themeConfig: ThemeStyles) =>
    css({
      width: "100%",
      position: "absolute",
      zIndex: 1,
      left: 0,
      backgroundColor: themeConfig.background,
      color: colors.black.hex,
      textAlign: "center",
      top: spacing.baseUnitMobile8,
      padding: themeConfig.paddingOnMobile,
      [mediaQuery.largerThanMobile]: {
        top: spacing.baseUnitMobile10,
      },
      [mediaQuery.desktopOrLarger]: {
        fontSize: fontSizes.heading.H6.desktop,
        padding: themeConfig.padding,
      },
    }),
  headline: (themeConfig: ThemeStyles) =>
    css({
      fontWeight: "bold",
      letterSpacing: letterSpacings.body.mouse,
      color: themeConfig.titleColor,
    }),
  headline2: (themeConfig: ThemeStyles) =>
    css({
      color: themeConfig.titleColor,
    }),
  counterWrapper: (themeConfig: ThemeStyles) =>
    css({
      display: "flex",
      fontWeight: "300",
      justifyContent: "center",
      alignItems: "center",
      color: themeConfig.counterColor,
      marginTop: spacing.baseUnitMobile3,
      gap: spacing.baseUnitMobile3,
      fontSize: fontSizes.heading.H1.mobile,
      [mediaQuery.desktopOrLarger]: {
        gap: spacing.baseUnitMobile4,
        fontSize: fontSizes.heading.H1.desktop,
      },
      "& div": {
        position: "relative",
        width: "58px",
        ":not(:last-of-type)": {
          "&::before": {
            content: `":"`,
            color: themeConfig.counterColor,
            position: "absolute",
            top: "-2px",
            right: `-15px`,
            fontsize: fontSizes.heading.H1.mobile,
            [mediaQuery.desktopOrLarger]: {
              top: "-5px",
              right: `-21px`,
              fontSize: fontSizes.heading.H1.desktop,
            },
          },
        },
      },
      p: {
        margin: `${spacing.baseUnitMobile2} 0 0`,
        color: colors.white.hex,
        fontSize: fontSizes.body.mouse,
        fontWeight: "normal",
      },
    }),
  imageWrapper: () =>
    css({
      display: "none",
      [mediaQuery.desktopOrLarger]: {
        display: "inline-block",
        width: "188px",
        height: "80px",
        position: "absolute",
        marginLeft: "auto",
        marginRight: "auto",
        top: spacing.baseUnitMobile3,
        left: "40%",
        right: 0,
      },
    }),
};
