import React from "react";

import { Container } from "../../elements/Container";
import { styles } from "./Banner.style";
import { useThemePattern } from "./utils/useThemePattern";
import { Image } from "../../elements/Image";
import Countdown from "react-countdown";

export interface BannerProps {
  title: string;
  description: string;
  dateToEndCampaign: string;
  optionalImage: { url: string; title?: string };
  themePattern: string;
  daysLabel: string;
  hoursLabel: string;
  minutesLabel: string;
  secondsLabel: string;
}

export const Banner: React.FC<BannerProps> = ({
  title,
  description,
  dateToEndCampaign,
  optionalImage,
  themePattern,
  daysLabel,
  hoursLabel,
  minutesLabel,
  secondsLabel,
}) => {
  const themeConfig = useThemePattern(themePattern);

  const counter = ({ formatted: { days, hours, minutes, seconds } }) => {
    return (
      <div css={styles.counterWrapper(themeConfig)}>
        <div>
          {days}
          <p>{daysLabel}</p>
        </div>
        <div>
          {hours}
          <p>{hoursLabel}</p>
        </div>
        <div>
          {minutes}
          <p>{minutesLabel}</p>
        </div>
        <div>
          {seconds}
          <p>{secondsLabel}</p>
        </div>
      </div>
    );
  };

  return (
    <section css={styles.wrapper(themeConfig)}>
      <Container noPaddingMobile noPaddingDesktop>
        <span css={styles.headline(themeConfig)}>{title}&nbsp;</span>
        <span css={styles.headline2(themeConfig)}>{description}</span>
        {dateToEndCampaign && <Countdown date={`${dateToEndCampaign}:00+02:00`} renderer={counter} />}
        {optionalImage && (
          <div css={styles.imageWrapper()}>
            <Image image={{ url: optionalImage.url, title: optionalImage.title }} />
          </div>
        )}
      </Container>
    </section>
  );
};
